
import Vue from 'vue';

export default Vue.extend({
  metaInfo() {
    return {
      titleTemplate: 'StaffNet'
    };
  },
  name: 'App',
  components: {},
  data: () => ({}),
  computed: {}
});
